import { jwtDecode } from "jwt-decode";
import { AuthService } from "@/services/AuthService";
const authService = new AuthService();
export function useUserStore() {
  return {
    login(token) {
      const userData = getUserFromToken(token);
      localStorage.setItem("user", JSON.stringify(userData)); // Persistência
      localStorage.setItem("token", token); // Persistência do token
    },
    async logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.clear();
      await authService.solicitarLogin();
    },
    isLoggedIn() {
      const token = localStorage.getItem("token");
      return token !== null && token !== undefined; // Verifica se o token está presente
    },
    getToken() {
      return localStorage.getItem("token");
    },
    getUser() {
      return JSON.parse(localStorage.getItem("user")); // Retorna as informações do usuário logado
    },
  };

  function getUserFromToken(token) {
    try {
      const decoded = jwtDecode(token);
      return {
        usuarioId: decoded.usuarioId,
        nome: decoded.sub,
        email: decoded.email,
        grupo: decoded.grupos[0],
        estabelecimentoId: decoded.estabelecimentoId,
        estabelecimentoNome: decoded.nomeEstabelecimento,
      };
    } catch (error) {
      console.error("Erro ao decodificar o token:", error);
    }
  }
}
