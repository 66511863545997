<template>
  <div class="cards">
    <div class="flex justify-between">
      <div class="titulo">Pagamentos e solicitações</div>
      <button
        title="Adicionar Novo Pagamento"
        class="bg-blue-800 hover:bg-blue-900 text-white py-1 px-4 rounded-md"
        @click="openAddPagamentoModal"
      >
        +
      </button>
    </div>
    <transition-group name="list" tag="div" class="list-container">
      <div
        class="card"
        v-for="pagamento in filteredPagamentos"
        :key="pagamento.id"
      >
        <div class="card-content">
          <div class="row">
            <span class="label">Mesa/Conta</span>
            <span>{{ pagamento.mesa }} / #{{ pagamento.contaId }} </span>
          </div>
          <div class="row">
            <span class="label">Cliente</span>
            <span>{{ pagamento.cliente }}</span>
          </div>
          <div class="row">
            <span class="label">Método de Pagamento</span>
            <span>{{ pagamento.metodoPagamentoNome }}</span>
          </div>
          <div class="row">
            <span class="label">Valor Total</span>
            <span>R$ {{ pagamento.valorTotal }}</span>
          </div>
        </div>
        <div class="card-buttons">
          <button
            class="button-status"
            :class="
              pagamento.status === 'ATIVA' || pagamento.status === 'AGUARDANDO'
                ? 'pagamento-solicitado'
                : 'invisible'
            "
            @click="() => handleButtonPagamentoClick(pagamento, 'ATIVA')"
          >
            SOLICITADO
          </button>
          <button
            class="button-status"
            :class="
              pagamento.status === 'CONCLUIDA'
                ? 'pagamento-pago'
                : pagamento.status === 'CANCELADA'
                ? 'invisible'
                : ''
            "
            @click="() => handleButtonPagamentoClick(pagamento, 'CONCLUIDA')"
          >
            PAGO
          </button>
          <button
            class="button-status"
            :class="
              pagamento.status === 'CANCELADA'
                ? 'pagamento-pago'
                : pagamento.status === 'CONCLUIDA'
                ? 'invisible'
                : ''
            "
            @click="() => handleButtonPagamentoClick(pagamento, 'CANCELADA')"
          >
            CANCELADO
          </button>
        </div>
      </div>
    </transition-group>
  </div>

  <div v-if="showConfirmModal" class="modal-overlay">
    <div class="modal-content">
      <p>{{ confirmMessage }}</p>
      <div class="modal-buttons">
        <button
          class="bg-green-600 text-white py-1 px-4 rounded-md hover:bg-green-700"
          @click="confirmAction"
        >
          Confirmar
        </button>
        <button
          class="bg-red-600 text-white py-1 px-4 rounded-md hover:bg-red-700"
          @click="closeConfirmModal"
        >
          Cancelar
        </button>
      </div>
    </div>
  </div>

  <!-- Modal para adicionar um novo pagamento -->
  <div v-if="showAddPagamentoModal" class="modal-overlay">
    <div class="modal-pagamento-content">
      <h3 class="font-bold mb-4 text-start">Adicionar Novo Pagamento</h3>
      <div class="flex flex-col">
        <label class="text-sm text-start">Mesa/Conta:</label>
        <select
          v-model="newPagamento.contaId"
          class="border-2 rounded-md p-2 mb-1"
        >
          <option value="" disabled selected>Selecione uma mesa</option>
          <option
            v-for="mesa in mesas"
            :key="mesa.contaId"
            :value="mesa.contaId"
          >
            {{ mesa.numero }} / #{{ mesa.contaId }}
          </option>
        </select>
      </div>
      <div class="flex flex-col">
        <label class="text-sm text-start">Método Pagamento:</label>
        <select
          v-model="newPagamento.metodoPagamentoId"
          class="border-2 rounded-md p-2 mb-1"
        >
          <option value="" disabled selected>
            Selecione um método de pagamento
          </option>
          <option
            v-for="metodo in metodosPagamento"
            :key="metodo.id"
            :value="metodo.id"
          >
            {{ metodo.nome }}
          </option>
        </select>
      </div>
      <div class="flex gap-2 mb-2">
        <div class="flex flex-col flex-1">
          <label class="text-sm text-start">Valor:</label>
          <input
            v-model="newPagamento.valor"
            type="number"
            class="border-2 rounded-md p-2 mb-1 text-end"
          />
        </div>
      </div>
      <div class="flex gap-2">
        <button
          @click="addPagamento"
          class="bg-green-600 hover:bg-green-700 text-white py-1 px-4 rounded-md"
        >
          Salvar
        </button>
        <button
          @click="closeAddPagamentoModal"
          class="bg-red-600 hover:bg-red-700 text-white py-1 px-4 rounded-md"
        >
          Cancelar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PagamentoService } from "@/services/PagamentoService";
import { useUserStore } from "@/store/userStore";
import { MesaService } from "@/services/MesaService";

const service = new PagamentoService();
const mesaService = new MesaService();

const userStore = useUserStore();

export default {
  props: {
    selectedMesa: Object,
  },
  async mounted() {
    const user = userStore.getUser();

    this.pagamentos = await service.findAll(user.estabelecimentoId);
    this.mesas = await mesaService.findAllAbertas(user.estabelecimentoId);
    this.metodosPagamento = await service.findAllMetodosPagamento();

    this.interval = setInterval(async () => {
      this.pagamentos = await service.findAll(user.estabelecimentoId);
    }, 5000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    getStyleButtonStatusPago(pagamento) {
      if (pagamento.status === "ATIVA" || pagamento.status === "AGUARDANDO") {
        return "display: none";
      } else {
        return "disabled: true";
      }
    },
    handleButtonPagamentoClick(pagamento, status) {
      if (pagamento.status === status) return;
      if (status === "CONCLUIDA") {
        this.showConfirmModal = true;
        this.confirmMessage = `Você tem certeza que deseja marcar o pagamento de R$ ${pagamento.valorTotal} como "PAGO"?`;
        this.confirmCallback = () => {
          // Chama o serviço para alterar o status
          service.changeStatus(pagamento.id, status);

          // Atualiza o status localmente
          this.pagamentos = this.pagamentos.map((pag) =>
            pag.id === pagamento.id ? { ...pag, status } : pag
          );

          this.closeConfirmModal();
        };
      } else {
        // Alteração sem confirmação
        service.changeStatus(pagamento.id, status, userStore.getToken());
        this.pagamentos = this.pagamentos.map((pag) =>
          pag.id === pagamento.id ? { ...pag, status } : pag
        );
      }
    },
    confirmAction() {
      if (this.confirmCallback) {
        this.confirmCallback();
      }
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
      this.confirmCallback = null;
      this.confirmMessage = "";
    },

    // adicionar pagamento
    openAddPagamentoModal() {
      this.showAddPagamentoModal = true;
    },
    closeAddPagamentoModal() {
      this.showAddPagamentoModal = false;
      this.resetNewPagamentoForm();
    },
    resetNewPagamentoForm() {
      this.newPagamento = {
        contaId: "",
        usuarioId: "",
        valor: "",
        metodoPagamentoId: "",
      };
    },
    async addPagamento() {
      if (
        this.newPagamento.contaId &&
        this.newPagamento.metodoPagamentoId &&
        this.newPagamento.valor
      ) {
        this.newPagamento.usuarioId = userStore.user.usuarioId;
        const pagamentoSalvo = await service.create(this.newPagamento);
        this.pagamentos.push(pagamentoSalvo);
        this.closeAddPagamentoModal();
      } else {
        alert("Por favor, preencha todos os campos obrigatórios!");
      }
    },
  },
  data() {
    return {
      interval: null,
      pagamentos: [],
      metodosPagamento: [],
      mesas: [],
      showConfirmModal: false,
      confirmCallback: null,
      confirmMessage: "",
      showAddPagamentoModal: false,
      newPagamento: {
        contaId: "",
        usuarioId: "",
        valor: "",
        metodoPagamentoId: "",
      },
    };
  },
  computed: {
    filteredPagamentos() {
      const statusOrder = {
        ATIVA: 1,
        CONCLUIDA: 2,
        CANCELADA: 3,
      };

      const filtered = this.selectedMesa
        ? this.pagamentos.filter(
            (pagamento) => pagamento.contaId === this.selectedMesa.contaId
          )
        : [...this.pagamentos];

      return filtered
        .slice()
        .sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);
    },
  },
};
</script>

<style scoped>
.cards {
  background-color: rgb(120, 250, 194);
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: rgb(57, 57, 57);
  box-sizing: border-box;
  min-width: 370px;
}
.card {
  padding: 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  background-color: #dadada;
  border-radius: 16px;
  width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}
.card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.card-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  margin-left: auto;
}
.button-status {
  padding: 8px;
  width: 110px;
  border: none;
  border-radius: 16px;
  background-color: #e0e0e0;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}
.button-status.active {
  background-color: #333;
  color: #fff;
}
.button-status:hover:not(.active) {
  background-color: #d3d3d3;
}
.row {
  display: flex;
  flex-direction: column;
}
.label {
  font-size: 12px;
  color: #888;
}
.titulo {
  font-size: 18px;
  font-weight: bold;
}
.pagamento-solicitado {
  background-color: rgb(226, 150, 27);
  color: white;
  font-weight: bold;
}
.pagamento-pago {
  background-color: rgb(16, 119, 0);
  color: white;
  font-weight: bold;
}
.pagamento-cancelado {
  background-color: rgb(191, 0, 0);
  color: white;
  font-weight: bold;
}
.list-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s ease;
}

.list-enter-from {
  transform: translateY(-20px);
  opacity: 0;
}

.list-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.list-leave-from {
  transform: translateY(0);
  opacity: 1;
}

.list-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-pagamento-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.invisible {
  display: none;
}
</style>
