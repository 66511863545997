<template>
  <header ref="topBar" class="top-bar">
    <div class="mesas">
      <button
        class="button-todas-mesas"
        :class="{ 'selected-mesa': selectedMesa === undefined }"
        @click="handleMesaClick(0)"
      >
        Todos os Pedidos
      </button>
      <button
        v-for="mesa in sortedMesas"
        :key="mesa.id"
        class="button-mesa"
        :class="{ 'selected-mesa': selectedMesa?.numero === mesa.numero }"
        @click="handleMesaClick(mesa)"
      >
        <strong
          >{{ "MESA " + mesa.numero }}
          {{ mesa.contaId ? "#" + mesa.contaId : "" }}
        </strong>
        <br />
        <span>{{ "Garçom: " + mesa.garcomNome }}</span> <br />
        <span>{{ "R$ " + mesa.valor.toFixed(2) }}</span> <br />
        <span class="status-mesa" :class="getStatus(mesa.status)">
          {{ getStatusLabel(mesa.status) }}
        </span>
        <span class="notifications">
          <span class="not1" title="Número de pedidos pendentes">{{
            mesa.pendentes ? mesa.pendentes : null
          }}</span>
          <span class="not2" title="Pedidos prontos para entrega">{{
            mesa.prontos ? mesa.prontos : null
          }}</span>
          <span class="not3" title="Solicitações de pagamentos pendentes">{{
            mesa.solicitacoesPagamentos ? mesa.solicitacoesPagamentos : null
          }}</span>
        </span>
      </button>
    </div>
  </header>
  <div v-if="showConfirmPopup" class="popup">
    <div class="popup-content">
      <p class="mensagem-confirmacao">
        Abertura da mesa {{ mesaToConfirm?.numero }}
      </p>
      <p style="display: flex; flex-direction: column; align-items: start">
        <span style="font-size: 12px; margin-bottom: 4px">Garçom: </span>
        <select class="garcom-select" v-model="selectedGarcomId">
          <option
            v-for="garcom in garcons"
            :key="garcom.garcomId"
            :value="garcom.garcomId"
          >
            {{ garcom.nome }}
          </option>
        </select>
      </p>
      <button class="confirmar" @click="confirmAberturaMesa">Abrir Mesa</button>
      <button class="cancelar" @click="closePopup">Permanecer Livre</button>
    </div>
  </div>
</template>

<script>
import { MesaService } from "@/services/MesaService";
import { UsuarioService } from "@/services/UsuarioService";

const mesaService = new MesaService();
const usuarioService = new UsuarioService();
import { useUserStore } from "@/store/userStore";

const userStore = useUserStore();

export default {
  props: {
    selectedMesa: Object,
  },
  emits: ["selectMesa"],
  data() {
    return {
      interval: null,
      mesas: [],
      garcons: [],
      selectedGarcomId: null,
      showConfirmPopup: false,
      mesaToConfirm: null,
    };
  },
  computed: {
    sortedMesas() {
      return [...this.mesas].sort((a, b) => {
        const statusOrder = {
          AGUARDANDO_ABERTURA: 1,
          ABERTA: 2,
          LIVRE: 3,
          PAGA: 3,
          FECHADA: 3,
        };

        // Primeiro, compara os status
        const statusComparison = statusOrder[a.status] - statusOrder[b.status];

        // Se os status forem diferentes, retorna a comparação de status
        if (statusComparison !== 0) {
          return statusComparison;
        }

        // Caso os status sejam iguais, ordena pelo número da mesa
        return a.numero.localeCompare(b.numero, undefined, { numeric: true });
      });
    },
  },
  async mounted() {
    const user = userStore.getUser();
    this.estabelecimentoId = user.estabelecimentoId;

    this.mesas = await mesaService.findAll(user.estabelecimentoId);
    this.garcons = await usuarioService.findAllGarcons(user.estabelecimentoId);

    this.interval = setInterval(async () => {
      this.mesas = await mesaService.findAll(user.estabelecimentoId);
    }, 5000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    getStatus(status) {
      if (status === "PAGA") status = "LIVRE";
      if (status === "FECHADA") status = "LIVRE";
      if (status === "PARCIALMENTE_PAGA") status = "ABERTA";
      return status.replace("_", "-").toLowerCase();
    },
    getStatusLabel(status) {
      if (status === "PAGA") status = "LIVRE";
      if (status === "FECHADA") status = "LIVRE";
      if (status === "PARCIALMENTE_PAGA") status = "ABERTA";
      return status.replace("_", " ").toUpperCase();
    },
    handleMesaClick(mesa) {
      if (mesa.status === "AGUARDANDO_ABERTURA") {
        this.mesaToConfirm = mesa;
        this.selectedGarcomId = mesa.garcomId;
        this.showConfirmPopup = true;
      } else {
        this.toggleMesa({ numero: mesa.numero, contaId: mesa.contaId });
      }
    },
    toggleMesa(mesa) {
      const mesaSelecionada = mesa?.numero
        ? { numero: mesa.numero, contaId: mesa.contaId }
        : undefined;

      this.$emit("selectMesa", mesaSelecionada);
    },
    confirmAberturaMesa() {
      if (this.mesaToConfirm) {
        mesaService.openConta(
          this.mesaToConfirm.contaId,
          this.selectedGarcomId
        );
        this.mesaToConfirm.status = "ABERTA";
        this.mesaToConfirm.garcomId = this.selectedGarcomId;
        // atualiza o nome do garçom na view
        this.mesaToConfirm.garcomNome = this.garcons.find(
          (garcom) => garcom.garcomId === this.selectedGarcomId
        ).nome;
      }
      this.showConfirmPopup = false;
      this.mesaToConfirm = null;
    },
    closePopup() {
      if (this.mesaToConfirm) {
        mesaService.closeConta(this.mesaToConfirm.contaId);
        this.mesaToConfirm.status = "LIVRE";
        this.mesaToConfirm.garcomId = this.selectedGarcomId;
        // atualiza o nome do garçom na view
        this.mesaToConfirm.garcomNome = this.garcons.find(
          (garcom) => garcom.garcomId === this.selectedGarcomId
        ).nome;
      }
      this.showConfirmPopup = false;
      this.mesaToConfirm = null;
    },
  },
};
</script>

<style scoped>
.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background-color: #333;
  display: flex;
  white-space: nowrap;
  overflow-y: auto;
  z-index: 1000;
}
.mesas {
  display: flex;
  gap: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #fff;
  padding: 8px;
}
.button-todas-mesas {
  width: 120px;
  background-color: #444;
  color: #fff;
  border-radius: 12px;
  text-align: center;
  text-wrap: wrap;
  font-weight: bold;
  font-size: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  padding: 8px;
}
.button-mesa {
  min-width: 120px;
  background-color: #444;
  color: #fff;
  border: none;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  padding: 8px;
}
.button-mesa:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}
.status-mesa {
  border-radius: 18px;
  padding: 4px 8px;
}
.livre {
  background-color: green;
}
.aguardando-abertura {
  background-color: red;
}
.aberta {
  background-color: darkblue;
}
.selected-mesa {
  border: 3px solid yellow;
}
.notifications {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: black;
  font-weight: bold;
}
.not1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: orange;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.not2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: green;
  color: white;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.not3 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: orange;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 300px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1100;
  border-radius: 12px;
}
.popup-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
}
.popup-content button {
  padding: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
.confirmar {
  background-color: green;
  color: #fff;
}
.cancelar {
  background-color: red;
  color: #fff;
}
.mensagem-confirmacao {
  font-weight: bold;
  font-size: 16px;
}
.garcom-select {
  margin-bottom: 8px;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: none;
  background-color: #f1f1f1;
  color: #333;
  font-size: 14px;
}
</style>
