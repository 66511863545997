import axios from "@/utils/AxiosPag";

export class MesaService {
  async findAll(estabelecimentoId) {
    const response = await axios
      .getInstance()
      .get(`/mesas/estabelecimento/${estabelecimentoId}`);
    return response.data;
  }

  async findAllAbertas(estabelecimentoId) {
    const response = await axios
      .getInstance()
      .get(`/mesas/abertas/estabelecimento/${estabelecimentoId}`);
    return response.data;
  }

  async openConta(contaId, garcomId) {
    await axios.getInstance().put(`/contas/${contaId}/open`, {
      garcomId,
    });
  }

  async closeConta(contaId) {
    await axios.getInstance().put(`/contas/${contaId}/close`);
  }
}
