<template>
  <component :is="isLoggedIn ? 'MainPage' : null" />
</template>

<script>
import { ref, onMounted } from "vue";
import { useUserStore } from "@/store/userStore";
import MainPage from "@/pages/MainPage.vue";
import { AuthService } from "@/services/AuthService";

export default {
  name: "App",
  components: {
    MainPage,
  },
  setup() {
    const userStore = useUserStore();
    const isLoggedIn = ref(userStore.isLoggedIn());
    const authService = new AuthService();

    onMounted(async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const state = urlParams.get("state");

      if (code != null) {
        const token = await authService.validacaoLogin(code, state);
        if (token) {
          userStore.login(token);
          // Remover o token da URL para que não fique exposto
          window.history.replaceState({}, document.title, "/");
        }
      }

      // Verificar se o token já está armazenado e o usuário está logado
      if (userStore.isLoggedIn()) {
        isLoggedIn.value = true;
      } else {
        isLoggedIn.value = false;
        userStore.logout();
        authService.solicitarLogin();
      }
    });

    return {
      isLoggedIn,
    };
  },
};
</script>
