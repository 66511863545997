import { useCookies } from "vue3-cookies";
import pkceChallenge from "pkce-challenge";
export default class PKCIUtil {
  static nameCookie = "challenge_restaurante";

  static async getCodeVerifier() {
    if (!PKCIUtil.getCookie().get(PKCIUtil.nameCookie)) {
      await this.createCodeVerifier();
    }

    return JSON.parse(
      JSON.stringify(PKCIUtil.getCookie().get(this.nameCookie))
    );
  }

  static async createCodeVerifier() {
    const challenge = await pkceChallenge();
    PKCIUtil.getCookie().set(PKCIUtil.nameCookie, JSON.stringify(challenge));
  }

  static deleteCodeVerifier() {
    PKCIUtil.getCookie().remove(PKCIUtil.nameCookie);
  }

  static getCookie() {
    const cookie = useCookies();
    return cookie.cookies;
  }
}
