import PKCIUtil from "@/utils/PKCIUtil";
import axios from "axios";
import axiosApi from "@/utils/AxiosPag";

export class AuthService {
  static URI = "/oauth2";

  async solicitarLogin() {
    const PkceDto = await PKCIUtil.getCodeVerifier();
    const params = {
      response_type: "code",
      client_id: process.env.VUE_APP_CLIENT_ID,
      redirect_uri: process.env.VUE_APP_REDIRECT_URI,
      code_challenge: PkceDto.code_challenge,
      code_challenge_method: "S256",
    };
    const urlCode =
      process.env.VUE_APP_URL_AUTHORIZATION_CODE +
      `/oauth2/authorize?response_type=${params.response_type}&client_id=${params.client_id}&redirect_uri=${params.redirect_uri}&code_challenge=${PkceDto.code_challenge}&code_challenge_method=${params.code_challenge_method}&prompt=login&state=aaaajklhhbsvjsfjhjfgdjklhkkjhsdjnbdfyutohvgh`;
    window.location.href = urlCode;
  }

  async getAccessToken(state, code) {
    const axiosApi = axios.create({
      baseURL: process.env.VUE_APP_URL_AUTHORIZATION_CODE,
    });
    const formData = new FormData();
    const PkceDto = await PKCIUtil.getCodeVerifier();
    formData.append("grant_type", "authorization_code");
    formData.append("code", code);
    formData.append("redirect_uri", process.env.VUE_APP_REDIRECT_URI);
    formData.append("code_verifier", PkceDto.code_verifier);
    formData.append("state", state);
    console.log(formData);
    const client = `${process.env.VUE_APP_CLIENT_ID}:${process.env.VUE_APP_CLIENT_SECRET}`;
    const encodedCredentials = btoa(client);
    return await axiosApi
      .post(AuthService.URI + "/token", formData, {
        headers: {
          Authorization: `Basic ${encodedCredentials}`,
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async APIlogout() {
    return await axiosApi.getInstance().post("/security/logout");
  }

  async validacaoLogin(code, state) {
    const token = await this.getAccessToken(state, code);
    PKCIUtil.deleteCodeVerifier();
    return token.access_token;
  }
}
