import axios from "axios";
import { useUserStore } from "@/store/userStore";

class AxiosPag {
  constructor() {
    if (AxiosPag.instance) {
      return AxiosPag.instance; // Retorna a instância existente
    }

    // Configuração inicial do Axios
    this.axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
    });

    this.axiosInstance.interceptors.request.use(
      async (config) => {
        const userStore = useUserStore();
        const token = userStore.getToken();
        config.headers.Authorization = "Bearer " + token;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    AxiosPag.instance = this; // Salva a instância
    return this;
  }

  getInstance() {
    return this.axiosInstance; // Retorna a instância do Axios
  }
}

// Exporta uma única instância
const axiosPag = new AxiosPag();
export default axiosPag;
