import axios from "@/utils/AxiosPag";

export class ProdutoService {
  async findAll(estabelecimentoId) {
    const response = await axios
      .getInstance()
      .get(`/produtos/estabelecimento/${estabelecimentoId}`);
    return response.data;
  }
}
