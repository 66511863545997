import axios from "@/utils/AxiosPag";
export class PedidoService {
  async findAll(estabelecimentoId) {
    const response = await axios
      .getInstance()
      .get(`/pedidos/estabelecimento/${estabelecimentoId}`);
    return response.data;
  }

  async changeStatus(pedidoId, contaId, valor, status) {
    await axios
      .getInstance()
      .put(`/pedidos/${pedidoId}/status`, { contaId, valor, status });
  }

  async create(pedido) {
    const response = await axios.getInstance().post(`/pedidos`, pedido);
    return response.data;
  }
}
