import axios from "@/utils/AxiosPag";

export class UsuarioService {
  async findAllGarcons(estabelecimentoId) {
    const response = await axios
      .getInstance()
      .get(`/garcons/estabelecimento/${estabelecimentoId}`);
    return response.data;
  }
}
