<template>
  <div class="flex flex-col">
    <TopBar @selectMesa="handleSelectMesa" :selectedMesa="selectedMesa" />
    <div class="flex mt-[150px] justify-between">
      <CardsPedidos :selectedMesa="selectedMesa" />
      <CardsPagamentos :selectedMesa="selectedMesa" />
    </div>
  </div>
</template>

<script>
import CardsPagamentos from "@/components/CardsPagamentos.vue";
import CardsPedidos from "@/components/CardsPedidos.vue";
import TopBar from "@/components/TopBar.vue";
export default {
  components: {
    TopBar,
    CardsPedidos,
    CardsPagamentos,
  },
  data() {
    return {
      selectedMesa: undefined,
    };
  },
  methods: {
    handleSelectMesa(mesa) {
      this.selectedMesa = mesa;
    },
  },
};
</script>
